export const SetBooleanValues = (form, fieldList) => {
  const revertBoolean = field => {
    const fieldValue = form[field];
    if (typeof fieldValue !== "boolean") {
      form[field] = fieldValue === "true" ? true : false;
    }
  };

  fieldList.forEach(field => revertBoolean(field));
  return form;
};
