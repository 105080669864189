<template>
  <pos-form
    @delete-pos="onDeletePos"
    @submit="onSubmit"
    :showDeleteButton="this.selectedPosId"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Api from "@/services/api";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import PosForm from "@/components/forms/PosForm.vue";
import { SetBooleanValues } from "@/utils/form-helpers";

export default {
  components: { PosForm },
  computed: {
    ...mapGetters({
      selectedPos: `pos/SELECTED_POS`,
      selectedPosId: `pos/SELECTED_POS_ID`
    }),
    submitMessage() {
      const message = this.selectedPosId ? "FORM.UPDATED" : "FORM.SUBMITTED";
      return this.$t(message);
    }
  },
  mounted() {
    this.subTitle = this.selectedPosId
      ? this.$t("MENU.EDIT")
      : this.$t("MENU.NEW") + " " + this.$t("PAGES.SALES_MANAGEMENT.POS.POS");

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.SALES_MANAGEMENT.POS.POS") },
      { title: this.$t("ROUTES.POS_LIST") },
      { title: this.subTitle }
    ]);

    this.pos = this.selectedPos;
  },
  destroyed() {
    this.clearSelectedPos();
  },
  methods: {
    ...mapActions({
      clearSelectedPos: "pos/CLEAR_SELECTED_POS",
      togglePosActiveStatus: "pos/TOGGLE_POS_ACTIVE_STATUS"
    }),
    onSubmit(pos) {
      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage =
        this.$t("PAGES.SALES_MANAGEMENT.POS.POS") + this.submitMessage;
      const errorMessage = this.$t("FORM.ERROR");

      const apiAction = this.selectedPosId ? Api.put : Api.post;

      let endPoint = "pos";
      endPoint += this.selectedPosId ? "/" + this.selectedPosId : "";

      const checkFields = [
        "hasSystemDefined",
        "hasPosSetup",
        "hasDelivered",
        "deliveryForm"
      ];

      SetBooleanValues(pos, checkFields);

      apiAction(endPoint, pos)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "pos-list" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    onDeletePos() {
      this.togglePosActiveStatus({
        posId: this.selectedPosId,
        isActive: !this.selectedPos.isActive
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.replace({ name: "pos-list" });
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
  }
};
</script>
